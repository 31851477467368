import { UnleashClient } from 'unleash-proxy-client';

export interface FeatureFlagContext {
  userId: string | 'anonymous';
  email?: string;
  anonymousId?: string;
}

export interface FeatureFlagServiceConfig {
  environment: 'production' | 'development';
  appName: string;
  storageProvider?: any;
  fetch?: any;
}

export class FeatureFlagServiceBase {
  private unleash: UnleashClient;
  private started: boolean;

  constructor(config: FeatureFlagServiceConfig) {
    const isProduction = config.environment === 'production';

    this.unleash = new UnleashClient({
      url: `https://ff${isProduction ? 'p' : 'd'}.tactiq.io/proxy`,
      clientKey: isProduction
        ? 'bnDc9wd9wnJbznRBdhrUB3M7Uy42Xwt4fpXEi2daQspEQapPkkCAfNByHXk8FkXp'
        : 'bhUTqmJMbFeZFuEfEegCTwTCyGAcpY7oTFuvWfrgtMWxKhRvGfcj4X86xi3hR6Ct',
      appName: config.appName,
      storageProvider: config.storageProvider,
      fetch: config.fetch,
      refreshInterval: 120000, // default is 15 sec, too often, we can live with longer updates
    });
    this.started = false;
  }

  async start(): Promise<void> {
    if (!this.started) {
      this.unleash
        .start()
        .then(() => {
          this.started = true;
        })
        .catch(() => {
          // do nothing, this is not important and is better to avoid error logging on a client about stuff they can't help with
        });
    }
  }

  stop(): void {
    this.unleash.stop();
  }

  isEnabled(featureName: string): boolean {
    return this.unleash.isEnabled(featureName);
  }

  getJSONVariant<T>(featureName: string): T | null {
    const variant = this.unleash.getVariant(featureName);
    if (!variant.enabled) return null;

    if (variant.enabled && variant.payload && variant.payload.type === 'json') {
      try {
        return JSON.parse(variant.payload.value);
      } catch {
        // we don't want malformed json breaking the app
      }
    }

    return null;
  }

  onUpdate(notifier: (service: FeatureFlagServiceBase) => void): () => void {
    this.unleash.on('update', () => notifier(this));

    return () => this.unleash.off('update', notifier);
  }

  async updateContext({
    userId,
    email,
    ...properties
  }: FeatureFlagContext &
    Record<string, string | undefined | number>): Promise<void> {
    this.unleash
      .updateContext({
        userId,
        properties: {
          ...properties,
          ...(email ? { email } : {}),
        },
      })
      .catch(() => {
        // do nothing, this is not important and is better to avoid error logging on a client about stuff they can't help with
      });
  }
}
