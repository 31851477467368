import React from 'react';
import { GlobalLoadingClasses, LoadingTextClasses } from './Common/Loading';
import { cx } from '../helpers/utils';
import { FormattedMessage } from 'react-intl';

interface Props {
  message?: string;
}

// Buttons here should be as vanilla as possible
export const ErrorWidget: React.FC = () => {
  const safeButtonClass =
    'inline-flex items-center rounded-md text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 gap-x-1.5 px-2.5 py-1.5 text-indigo-600 bg-indigo-50 hover:bg-indigo-100';
  return (
    <div>
      <FormattedMessage
        defaultMessage="Please try <btn1>reloading this page</btn1> or <btn2>chatting to our support</btn2>."
        values={{
          btn1: (chunks) => (
            <button
              className={safeButtonClass}
              onClick={() => window.location.reload()}
            >
              {chunks}
            </button>
          ),
          btn2: (chunks) => (
            <button
              className={safeButtonClass}
              onClick={() => {
                window.Intercom && window.Intercom('show');
              }}
            >
              {chunks}
            </button>
          ),
        }}
      />
    </div>
  );
};

export const ErrorPage: React.FC<Props> = function (props: Props) {
  return (
    <div className={GlobalLoadingClasses}>
      <div className={LoadingTextClasses}>
        {props.message ?? 'Oops! Something went wrong :('}
      </div>
      <img
        src="https://uploads-ssl.webflow.com/61120cb2509e011efcf0b1e4/631e79e294d7efeb5729472f_r2.gif"
        alt="Sad robot"
      />
      <div className={cx(LoadingTextClasses, 'mt-[50px]')}>
        <ErrorWidget />
      </div>
      <div className={cx(LoadingTextClasses, 'max-w-[500px]')}>
        <FormattedMessage defaultMessage="If you're seeing this message and are translating the page, please reach out to our support team and let us know what language you're trying to translate to." />
      </div>
    </div>
  );
};
