import { IntlShape } from 'react-intl';
import {
  TransformedRawTranscript,
  Block as TranscriptBlock,
  FullMeeting,
} from '../models/meeting';
import omit from 'lodash/omit';
import {
  AutosaveTimestampOption,
  generateMeetingLink,
  getParticipantsNames,
  isBlockHighlighted,
  MeetingLinkOrigin,
  RawBlock,
  RawTranscript,
  transformAndCombineBlocks,
} from '@tactiq/model';
import { baseURL } from './firebase/config';
import {
  MeetingAiPromptOutput,
  MeetingPlatform,
  Tag,
  UserSettingsAutosaveTimestampOption,
} from '../graphql/operations';

const transformBlocks = (sourceBlocks: RawBlock[]): TranscriptBlock[] => {
  if (!sourceBlocks.length) return [];

  return sourceBlocks.map((block): TranscriptBlock => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { messageVersion, ...rest } = block;

    return {
      ...rest,
      fromStart: toHrMinSec(
        block.timestamp - sourceBlocks[0].timestamp,
        false,
        true
      ),
      fromStartHM: toHrMinSec(
        block.timestamp - sourceBlocks[0].timestamp,
        true,
        false
      ),
    };
  });
};

export const transformTranscript = (
  rawTranscript: RawTranscript
): TransformedRawTranscript => {
  const transcript: TransformedRawTranscript = {
    ...omit(rawTranscript, 'blocks', 'participants', 'translation'),
    blocks: transformBlocks(rawTranscript.blocks),
    participants: [],
    ...(rawTranscript.translation
      ? {
          translation: {
            ...rawTranscript.translation,
            blocks: transformBlocks(rawTranscript.translation.blocks),
          },
        }
      : {}),
  };

  transcript.participants = Array.from(
    new Set(
      Object.getOwnPropertyNames(transcript.devices ?? {})
        .filter(
          (x) =>
            x !== transcript.devices[x] &&
            transcript.blocks?.find((b) => b.deviceId === x)
        )
        .map((x) => transcript.devices[x])
    )
  ).sort();

  return transcript;
};

export function toHrMinSec(
  durationMs: number,
  useLetters: boolean,
  includeSeconds: boolean
): string {
  const hours = Math.floor(durationMs / 3600000);
  const minutes = includeSeconds
    ? Math.floor((durationMs % 3600000) / 60000)
    : Math.ceil((durationMs % 3600000) / 60000);
  const seconds = Math.min(
    Math.ceil(((durationMs % 3600000) % 60000) / 1000),
    59
  );

  return (
    (hours ? `${hours}${useLetters ? ' h ' : ':'}` : '') +
    `${minutes}${useLetters ? ' min ' : ':'}`.padStart(3, '0') +
    (includeSeconds
      ? `${seconds}${useLetters ? ' sec' : ''}`.padStart(2, '0')
      : '')
  );
}

export const toMarkdown = (
  meeting: FullMeeting,
  transcript: TransformedRawTranscript,
  timestampOption: UserSettingsAutosaveTimestampOption,
  tags: Tag[],
  screenshotUrls: Record<string, string>
): string => {
  const blockGroups = transformAndCombineBlocks(
    transcript.blocks,
    toModelTimestampOption(timestampOption),
    tags
  );
  const highlights = blockGroups.filter(isBlockHighlighted);

  return `# ${meeting.title}

  Meeting started: ${new Date(transcript.createdAt).toLocaleString()}
  Duration: ${
    Math.floor((meeting.speechDuration || meeting.duration) / 60).toString() +
    ' minutes'
  }
  Participants: ${getParticipantsNames(meeting.participants).join(', ')}

  [View original transcript](${generateMeetingLink(
    baseURL,
    { meetingId: meeting.id },
    MeetingLinkOrigin.TXT_EXPORT
  )})

  ${meeting?.aiOutputs
    .filter((x) => x.content)
    .map(
      (x) => `## ${x.promptTitle ?? 'Generated Content'}

    ${convertAIOutputContentToString(x)}`
    )
    .join('\n')}

  ${
    transcript.notes
      ? `## Notes

  ${transcript.notes.content}`
      : ''
  }
  
  ${
    highlights.length > 0
      ? `
    ## Highlights

    ${highlights
      .map((h) => {
        if (h.type === 'screenshot') {
          return `![Screenshot](${screenshotUrls[h.transcript]})`;
        }

        return `> ${h.displayTimestamp} ${h.speakerName}: ${h.transcript}`;
      })
      .join('\n')}`
      : ''
  }

  ## Transcript

  ${blockGroups
    .map((b) => {
      if (b.type === 'screenshot') {
        return `![Screenshot](${screenshotUrls[b.transcript]})`;
      }

      return `${b.displayTimestamp} ${b.speakerName}: ${b.transcript}`;
    })
    .join('\n')}
  `;
};

export const convertAIOutputContentToString = (
  output: MeetingAiPromptOutput | undefined
): string => {
  if (!output?.content) {
    return '';
  } else if (output.content.__typename === 'MeetingAIOutputTypeString') {
    return output.content.text;
  } else if (output.content.__typename === 'MeetingAIOutputTypeActionItems') {
    return (output.content.actionItems ?? [])
      .map(({ participantName, actions }) => {
        const actionItems = actions
          .map(({ content, isDone }) => {
            return `- [${isDone ? 'x' : ' '}] ${content}`;
          })
          .join('\n');

        return `## ${participantName}\n${actionItems}`;
      })
      .join('\n\n');
  } else {
    return '';
  }
};

export function getUserFriendlyPlatform(
  platform: string,
  intl: IntlShape
): string {
  switch (platform) {
    case MeetingPlatform.GOOGLE_MEET:
      return 'Google Meet';
    case MeetingPlatform.MS_TEAMS:
      return 'Microsoft Teams';
    case MeetingPlatform.ZOOM:
      return 'Zoom';
    case MeetingPlatform.ZOOM_API:
      return 'Zoom Desktop';
    case MeetingPlatform.ZOOM_DESKTOP:
      return 'Zoom Desktop';
    case MeetingPlatform.WEBEX_API:
      return 'Webex Desktop';
    case MeetingPlatform.UPLOADED_TRANSCRIPT:
      return intl.formatMessage({
        defaultMessage: 'Uploaded Transcript',
      });
    case MeetingPlatform.UPLOADED_AUDIO:
      return intl.formatMessage({
        defaultMessage: 'Uploaded Audio',
      });
    case MeetingPlatform.UPLOADED_VIDEO:
      return intl.formatMessage({
        defaultMessage: 'Uploaded Video',
      });
    default:
      return platform;
  }
}

export const toModelTimestampOption = (
  timestampOption: UserSettingsAutosaveTimestampOption
): AutosaveTimestampOption => {
  switch (timestampOption) {
    case UserSettingsAutosaveTimestampOption.DURATION:
      return AutosaveTimestampOption.DURATION;
    case UserSettingsAutosaveTimestampOption.UTC:
      return AutosaveTimestampOption.UTC;
    case UserSettingsAutosaveTimestampOption.NONE:
      return AutosaveTimestampOption.NONE;
    default:
      return AutosaveTimestampOption.NONE;
  }
};
