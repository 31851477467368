import { FeatureFlagServiceBase } from '@tactiq/model';
import { isProduction } from '../firebase/config';
import { createContext, useContext } from 'react';

class FeatureFlagService extends FeatureFlagServiceBase {
  isPreviewMeetingsCleanupWarningEnabled(): boolean {
    return this.isEnabled('webapp-enable-preview-meetings-cleanup-warning');
  }

  areActionItemMeetingKitItemsEnabled(): boolean {
    return this.isEnabled('web-enable-meetingkititems-actionitems');
  }

  isMeetingKitPublishingEnabled(): boolean {
    return this.isEnabled('web-enable-meetingkit-publishing');
  }

  isGoogleCalendarWritePermissionsEnabled(): boolean {
    return this.isEnabled('web-enable-google-calendar-write-permissions');
  }

  isReportingEnabled(): boolean {
    return this.isEnabled('web-enable-reporting');
  }

  isSetupPageTypeformEnabled(): boolean {
    return this.isEnabled('web-enable-setuppage-typeform');
  }

  isAiSummaryPreviewExperimentEnabled(): boolean {
    return this.isEnabled('ai-summary-preview-experiment') ?? false;
  }

  isAutomaticWorkflowTriggersEnabled(): boolean {
    return this.isEnabled('automatic-workflow-triggers');
  }

  isWorkflowPublishingEnabled(): boolean {
    return this.isEnabled('workflows-publishing');
  }

  isAlwaysConfirmRecordingSettingEnabled(): boolean {
    return this.isEnabled('always-confirm-recording-setting');
  }

  isNewMeetingListsEnabled(): boolean {
    return this.isEnabled(WebappEnabledNewMeetingLists);
  }

  isMacAppMvpRolloutEnabled(): boolean {
    return this.isEnabled('mac-app-mvp-rollout');
  }

  isTeamAdvancedAuthEnabled(): boolean {
    return this.isEnabled('webapp-enable-advancedauth');
  }

  isDeactivateTeamMemberEnabled(): boolean {
    return this.isEnabled('webapp-enable-deactivate-team-member');
  }

  getAvailableIntegrations(): Set<string> {
    const baseIntegrations = new Set(['slack']);
    const variant =
      this.getJSONVariant<string[]>('workflows-integrations-preview') ?? [];
    return new Set([...baseIntegrations, ...variant]);
  }

  getSessionRecordingEvents() {
    return this.getJSONVariant<{ on: string; off: string }>(
      'session-recording'
    );
  }

  getFlagsForABTesting(): Record<string, boolean> {
    return {
      [`flag_${WebappEnabledNewMeetingLists.replaceAll('-', '_')}`]:
        this.isNewMeetingListsEnabled(),
    };
  }

  getTeamJoinPageEnabled(): boolean {
    return this.isEnabled('auto-join-team-page');
  }

  getDownloadCompanionAppBanner(): boolean {
    return this.isEnabled('download-companion-app-banner');
  }

  getUpgradePaidSeatBanner(): boolean {
    return this.isEnabled('upgrade-paid-seat-banner');
  }

  showUserProfileModal(): boolean {
    return this.isEnabled('show-user-profile-modal');
  }
}

const WebappEnabledNewMeetingLists = 'webapp-enable-newmeetinglists';

const featureFlagService = new FeatureFlagService({
  appName: 'tactiq-webapp',
  environment: isProduction() ? 'production' : 'development',
});

export default featureFlagService;

const FeatureFlagContext = createContext<FeatureFlagService | undefined>(
  undefined
);

export const FeatureFlagProvider = FeatureFlagContext.Provider;
export type FeatureFlagOverride = Partial<FeatureFlagService>;

export function useFeatureFlags() {
  const featureFlagService = useContext(FeatureFlagContext);
  if (!featureFlagService) {
    throw new Error(
      'useFeatureFlags must be used within a FeatureFlagProvider'
    );
  }
  return featureFlagService;
}
