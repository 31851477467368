import { InMemoryCache, Cache } from '@apollo/client';
import uniqBy from 'lodash/uniqBy';

export const cache = new InMemoryCache({
  typePolicies: {
    // Workflow Definitions have id fields but they are not globally unique.
    // We need to turn off normalization for so we don't get bleeding across
    WorkflowDefinition: { keyFields: false },
    Node: { keyFields: false },
    NodeData: { keyFields: false },
    Edge: { keyFields: false },

    Query: {
      fields: {
        meetings: {
          // Don't cache separate results based on
          // any of this field's arguments.
          keyArgs: ['type', 'spaceId'],

          // Concatenate the incoming list items with
          // the existing list items.
          merge(existing, incoming) {
            // If the incoming offset is 0, so we can just return it,
            // no need to merge.
            if (incoming.offset === 0) {
              return incoming;
            }
            // Slicing is necessary because the existing data is
            // immutable, and frozen in development.
            const merged = existing ? existing.meetings.slice(0) : [];
            for (let i = 0; i < incoming.meetings.length; ++i) {
              merged[incoming.offset + i] = incoming.meetings[i];
            }
            return {
              ...incoming,
              meetings: uniqBy(merged, '__ref').filter((m) => m),
            };
          },
        },
      },
    },
  },
});

export async function clearCache(): Promise<void> {
  return cache.reset();
}

export function evictCache(evictOptions: Cache.EvictOptions): boolean {
  return cache.evict(evictOptions);
}
