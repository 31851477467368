import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingList,
  FloatingNode,
  FloatingPortal,
  FloatingTree,
  offset,
  Placement,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useFloatingTree,
  useHover,
  useInteractions,
  useListItem,
  useListNavigation,
  useMergeRefs,
  useRole,
  useTypeahead,
} from '@floating-ui/react';
import * as React from 'react';
import { ReactNode } from 'react';
import { cx, usePortalRoot } from '../../helpers/utils';
import { ChevronRight } from 'lucide-react';
import { Link, LinkProps } from 'react-router-dom';
import { createStopPropagationHandler } from '../../app/Transcripts/common/stopPropagation';

const MenuContext = React.createContext<{
  getItemProps: (
    userProps?: React.HTMLProps<HTMLElement>
  ) => Record<string, unknown>;
  activeIndex: number | null;
  setActiveIndex: React.Dispatch<React.SetStateAction<number | null>>;
  setHasFocusInside: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
}>({
  getItemProps: () => ({}),
  activeIndex: null,
  setActiveIndex: () => {},
  setHasFocusInside: () => {},
  isOpen: false,
});

type MenuProps = {
  trigger?: React.ReactElement<HTMLButtonElement | HTMLLinkElement>;
  nested?: boolean;
  placement?: Placement;
  children?: ReactNode;
  label?: ReactNode;
  icon?: ReactNode;
  onOpenChange?: (isOpen: boolean) => void;
  widthClasses?: string;
};

const menuClasses =
  'flex flex-col absolute left-0 origin-top-left rounded-menu bg-white p-1 shadow-lg ring-1 ring-slate-200 focus:outline-none p-2 z-Menu max-h-dvh overflow-y-auto';

/**
 * The dropdown menu itself
 */
export const MenuComponent = React.forwardRef<
  HTMLButtonElement,
  MenuProps & React.HTMLProps<HTMLButtonElement>
>(function MenuComponent(
  {
    children,
    label,
    icon,
    onOpenChange,
    placement,
    widthClasses = 'min-w-64 max-w-72',
    ...props
  },
  forwardedRef
) {
  const root = usePortalRoot();
  const [isOpen, setIsOpen] = React.useState(false);
  const [hasFocusInside, setHasFocusInside] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);

  const elementsRef = React.useRef<Array<HTMLButtonElement | null>>([]);
  const labelsRef = React.useRef<Array<string | null>>([]);
  const parent = React.useContext(MenuContext);

  const tree = useFloatingTree();
  const nodeId = useFloatingNodeId();
  const parentId = useFloatingParentNodeId();
  const item = useListItem();

  const isNested = parentId != null;

  const { floatingStyles, refs, context } = useFloating<HTMLButtonElement>({
    nodeId,
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: placement ?? (isNested ? 'right-start' : 'bottom-start'),
    middleware: [
      // Shift in and up a little bit
      offset({ mainAxis: isNested ? 0 : 4, alignmentAxis: isNested ? -4 : 0 }),
      // Flip the direction if off screen
      flip(),
      // Re-position on both axis so we never have content off screen
      shift({ mainAxis: true, crossAxis: true }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, {
    enabled: isNested,
    delay: { open: 75 },
    handleClose: safePolygon({ blockPointerEvents: true }),
  });
  const click = useClick(context, {
    event: 'mousedown',
    toggle: !isNested,
    ignoreMouse: isNested,
  });
  const role = useRole(context, { role: 'menu' });
  const dismiss = useDismiss(context, { bubbles: true });
  const listNavigation = useListNavigation(context, {
    listRef: elementsRef,
    activeIndex,
    nested: isNested,
    onNavigate: setActiveIndex,
  });
  const typeahead = useTypeahead(context, {
    listRef: labelsRef,
    onMatch: isOpen ? setActiveIndex : undefined,
    activeIndex,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [hover, click, role, dismiss, listNavigation, typeahead]
  );

  // Event emitter allows you to communicate across tree components.
  // This effect closes all menus when an item gets clicked anywhere
  // in the tree.
  React.useEffect(() => {
    if (!tree) return;

    function handleTreeClick() {
      setIsOpen(false);
    }

    function onSubMenuOpen(event: { nodeId: string; parentId: string }) {
      if (event.nodeId !== nodeId && event.parentId === parentId) {
        setIsOpen(false);
      }
    }

    tree.events.on('click', handleTreeClick);
    tree.events.on('menuopen', onSubMenuOpen);

    return () => {
      tree.events.off('click', handleTreeClick);
      tree.events.off('menuopen', onSubMenuOpen);
    };
  }, [tree, nodeId, parentId]);

  React.useEffect(() => {
    if (isOpen && tree) {
      tree.events.emit('menuopen', { parentId, nodeId });
    }
  }, [tree, isOpen, nodeId, parentId]);

  React.useEffect(() => {
    onOpenChange?.(isOpen);
  }, [isOpen, onOpenChange]);

  return (
    <FloatingNode id={nodeId}>
      {React.cloneElement(props.trigger ?? <button />, {
        ref: useMergeRefs([refs.setReference, item.ref, forwardedRef]),
        tabIndex: !isNested
          ? undefined
          : parent.activeIndex === item.index
            ? 0
            : -1,
        role: isNested ? 'menuitem' : undefined,
        'data-open': isOpen ? '' : undefined,
        'data-nested': isNested ? '' : undefined,
        'data-focus-inside': hasFocusInside ? '' : undefined,
        className: isNested
          ? cx(
              isOpen ? activeMenuItemClasses : '',
              menuItemClasses,
              props.trigger?.props.className,
              'flex justify-between'
            )
          : props.trigger?.props.className,
        ...getReferenceProps(
          parent.getItemProps({
            ...props,
            onFocus(event: React.FocusEvent<HTMLButtonElement>) {
              props.onFocus?.(event);
              setHasFocusInside(false);
              parent.setHasFocusInside(true);
            },
          })
        ),
        ...getReferenceProps({
          onClick: createStopPropagationHandler(() => {}),
        }),
        ...(label
          ? {
              children: (
                <>
                  <div className="flex gap-3">
                    {icon}
                    <div className="min-w-0">{label}</div>
                  </div>
                  {isNested && (
                    <ChevronRight size="1rem" className="text-slate-500" />
                  )}
                </>
              ),
            }
          : undefined),
      })}
      <MenuContext.Provider
        value={{
          activeIndex,
          setActiveIndex,
          getItemProps,
          setHasFocusInside,
          isOpen,
        }}
      >
        <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
          {isOpen ? (
            <FloatingPortal root={root}>
              <FloatingFocusManager
                context={context}
                modal={false}
                initialFocus={isNested ? -1 : 0}
                returnFocus={!isNested}
              >
                <div
                  ref={refs.setFloating}
                  className={cx(menuClasses, widthClasses, props.className)}
                  style={floatingStyles}
                  {...getFloatingProps()}
                >
                  {children}
                </div>
              </FloatingFocusManager>
            </FloatingPortal>
          ) : null}
        </FloatingList>
      </MenuContext.Provider>
    </FloatingNode>
  );
});

const menuItemClasses =
  'flex-grow cursor-pointer disabled:cursor-default appearance-none items-center overflow-hidden transition-colors truncate p-2 text-left text-slate-800 text-sm rounded-md flex gap-3 focus:outline-none';

const activeMenuItemClasses = '!bg-slate-100';

export const Item = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  | (React.ButtonHTMLAttributes<HTMLButtonElement> & {
      as?: 'button';
      icon?: ReactNode;
      fireTreeEvents?: boolean;
      truncate?: boolean;
    })
  | (React.AnchorHTMLAttributes<HTMLAnchorElement> & {
      as: 'a';
      icon?: ReactNode;
      fireTreeEvents?: boolean;
      truncate?: boolean;
    })
  | (LinkProps & {
      as: 'link';
      icon?: ReactNode;
      fireTreeEvents?: boolean;
      truncate?: boolean;
    })
>(function MenuItem(
  { children, icon, fireTreeEvents = true, truncate, ...props },
  forwardedRef
) {
  const menu = React.useContext(MenuContext);
  const tree = useFloatingTree();
  const item = useListItem({
    label:
      props.as !== 'a' && props.as !== 'link' && props.disabled
        ? null
        : String(children),
  });
  const isActive = item.index === menu.activeIndex;
  const commonSettings = {
    ref: useMergeRefs([item.ref, forwardedRef]),
    children: (
      <>
        {icon && <div className="flex-shrink-0">{icon}</div>}
        <div
          className={cx(
            'flex-grow text-ellipsis leading-none',
            truncate ? 'truncate' : undefined
          )}
        >
          {children}
        </div>
      </>
    ),
    className: cx(
      isActive ? activeMenuItemClasses : '',
      'disabled:opacity-50',
      menuItemClasses,
      props.className
    ),
    type: 'button' as const,
    role: 'menuitem',
    tabIndex: isActive ? 0 : -1,
  };

  const onFocus = (event: React.FocusEvent<any>) => {
    menu.setHasFocusInside(true);
    props.onFocus?.(event);
  };

  const onClick = (event: React.MouseEvent<any>) => {
    fireTreeEvents && tree?.events.emit('click');
    props.onClick?.(event);
  };

  if (props.as === 'link') {
    return (
      <Link
        {...props}
        {...commonSettings}
        {...menu.getItemProps({ onClick, onFocus })}
      />
    );
  }

  if (props.as === 'a') {
    return (
      <a
        {...props}
        {...commonSettings}
        {...menu.getItemProps({ onClick, onFocus })}
      />
    );
  }

  return (
    <button
      {...props}
      {...commonSettings}
      {...menu.getItemProps({ onClick, onFocus })}
    />
  );
});

/**
 * A hr with styling to negate the padding of the parent Menu
 */
export const Divider: React.FC = () => (
  <hr className="-mx-2 my-2 border-slate-200 border-t" />
);

/**
 * Used with a divider to name an area of the menu
 */
export const Label: React.FC<{ children: ReactNode }> = (props) => (
  <div className="p-2 font-semibold text-slate-400 text-xs uppercase">
    {props.children}
  </div>
);

/**
 * Trigger is a slot component that allows the root component
 * to hoist out the trigger button and clone the required props on to it.
 */
export const Trigger: React.FC<{ children: React.ReactElement }> = (props) =>
  props.children;

/**
 * RootMenu checks the children to find the trigger and removes it
 * from the children before passing it to the real Menu component.
 *
 * It also handles wrapping the menus in thier required FloatingTree
 */
export const RootMenu = React.forwardRef<
  HTMLButtonElement,
  MenuProps & React.HTMLProps<HTMLButtonElement>
>(function Menu(props, ref) {
  const parentId = useFloatingParentNodeId();

  let trigger;
  const children: React.ReactElement[] = [];

  React.Children.forEach(props.children, (child) => {
    if (!React.isValidElement(child)) return;
    if (child.type === Trigger) return (trigger = child.props.children);
    children.push(child);
  });

  if (parentId === null) {
    return (
      <FloatingTree>
        <MenuComponent {...props} trigger={trigger} ref={ref}>
          {children}
        </MenuComponent>
      </FloatingTree>
    );
  }

  return (
    <MenuComponent {...props} trigger={trigger} ref={ref}>
      {children}
    </MenuComponent>
  );
});

export const Menu = Object.assign(RootMenu, {
  Trigger,
  Label,
  Item,
  Divider,
});
