import { logger } from '@tactiq/model';

const dummyConfig = {
  projectId: 'testfirebaseauth-f5df6',
  appId: '1:776425480797:web:16f7b697e290e012bb23b4',
  storageBucket: 'testfirebaseauth-f5df6.appspot.com',
  locationId: 'us-central',
  apiKey: 'AIzaSyCje8NQSmYgswaQtKIdC980PY1lEgyQ8wM',
  authDomain: 'testfirebaseauth-f5df6.firebaseapp.com',
  messagingSenderId: '776425480797',
  measurementId: 'G-FKHVZLW3S9',
};

export const firebaseConfig = Object.freeze(
  process.env.FIREBASE_CONFIG
    ? JSON.parse(process.env.FIREBASE_CONFIG)
    : dummyConfig
);

logger.configure({
  serviceName: `${firebaseConfig.projectId}-web`,
  serviceVersion: process.env.BUILD_SHA,
});

export const serverUri = `https://us-central1-${firebaseConfig.projectId}.cloudfunctions.net/app`;
export const GRAPHQL_WS_URL = `${(process.env.API_BASE_URL || '')
  .replace('https://', 'wss://')
  .replace('http://', 'ws://')}/api/2/graphql`;

export const baseDomain = `${firebaseConfig.projectId}.firebaseapp.com`;

const devBaseUrl = process.env.BASE_URL
  ? process.env.BASE_URL
  : `https://${baseDomain}`;

export const baseURL =
  process.env.NODE_ENV === 'production' ? 'https://app.tactiq.io' : devBaseUrl;

export const google_client_id =
  process.env.GOOGLE_CLIENT_ID ||
  '776425480797-e5gs1am9nqshamc3mqlt9csbqhod1img.apps.googleusercontent.com';

export const isProduction = (): boolean =>
  process.env.NODE_ENV === 'production';

export const projectId = firebaseConfig.projectId;
