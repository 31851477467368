import { Lock, Zap } from 'lucide-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { cx } from '../../helpers/utils';
import { Button } from '../buttons';

export type AlertBaseProps = {
  title?: React.ReactNode;
  description?: React.ReactNode;
  variant?: 'filled' | 'light';
  action?: React.ReactNode;
  alternateIcon?: React.ReactNode;
  compact?: boolean;
  upgradeAction?: () => void;
};

type GeneralAlertProps = AlertBaseProps & {
  severity:
    | 'error'
    | 'warning'
    | 'info'
    | 'success'
    | 'neutral'
    | 'default'
    | 'locked';
  upgradeAction?: never;
};

type AlertUpgradeProps = AlertBaseProps & {
  severity: 'upgrade';
  upgradeAction: () => void;
};

type AlertProps = GeneralAlertProps | AlertUpgradeProps;

type VariantSeverityProps = {
  [A in NonNullable<AlertProps['variant']>]: {
    [B in AlertProps['severity']]: string;
  };
};

const lightWrapper = 'border border-slate-200 bg-white w-full';
const variantWrapperClasses: VariantSeverityProps = {
  light: {
    error: lightWrapper,
    warning: lightWrapper,
    info: lightWrapper,
    success: lightWrapper,
    neutral: lightWrapper,
    default: lightWrapper,
    upgrade: lightWrapper,
    locked: lightWrapper,
  },
  filled: {
    error: 'bg-red-50 border border-red-200',
    warning: 'bg-yellow-50 border border-yellow-300',
    info: 'bg-blue-50 border border-blue-200',
    success: 'bg-green-50 border border-green-300',
    neutral: 'bg-slate-50 border border-slate-200',
    default: 'bg-white border border-slate-200',
    upgrade: lightWrapper,
    locked: lightWrapper,
  },
};

const lightIcon = 'p-0.5 w-[5px] self-stretch rounded';
const variantIconClasses: VariantSeverityProps = {
  light: {
    error: cx(lightIcon, 'bg-red-500'),
    warning: cx(lightIcon, 'bg-yellow-500'),
    info: cx(lightIcon, 'bg-indigo-500'),
    success: cx(lightIcon, 'bg-green-500'),
    neutral: cx(lightIcon, 'bg-slate-500'),
    default: cx(lightIcon, 'bg-white'),
    upgrade: '',
    locked: '',
  },
  filled: {
    error: 'text-red-600',
    warning: 'text-yellow-600',
    info: 'text-blue-600',
    success: 'text-green-600',
    neutral: 'text-slate-800',
    default: 'text-slate-800',
    upgrade: 'text-slate-800',
    locked: 'text-slate-800',
  },
};

const variantTitleClasses: VariantSeverityProps = {
  light: {
    error: '',
    warning: '',
    info: '',
    success: '',
    neutral: '',
    default: '',
    upgrade: '',
    locked: '',
  },
  filled: {
    error: 'text-red-800',
    warning: 'text-yellow-800',
    info: 'text-blue-800',
    success: 'text-green-800',
    neutral: 'text-slate-800',
    default: 'text-slate-800',
    upgrade: 'text-slate-800',
    locked: 'text-slate-800',
  },
};

const variantTextClasses: VariantSeverityProps = {
  light: {
    error: '',
    warning: '',
    info: '',
    success: '',
    neutral: '',
    default: '',
    upgrade: '',
    locked: '',
  },
  filled: {
    error: 'text-red-700',
    warning: 'text-yellow-700',
    info: 'text-blue-700',
    success: 'text-green-700',
    neutral: 'text-slate-700',
    default: 'text-slate-800',
    upgrade: 'text-slate-800',
    locked: 'text-slate-800',
  },
};

export const Alert: React.FC<AlertProps> = ({
  title,
  description,
  severity,
  variant = 'filled',
  action,
  alternateIcon,
  compact = false,
  upgradeAction,
}) => {
  let icon = null;
  let actionButton = null;
  const iconClasses = cx(
    variantIconClasses[variant][severity],
    'flex-shrink-0 justify-center self-center'
  );

  if (severity === 'upgrade') {
    actionButton = (
      <Button
        size="medium"
        variant="neutral-secondary"
        upgrade
        onClick={() => upgradeAction?.()}
      >
        <FormattedMessage defaultMessage="Upgrade" />
      </Button>
    );
  }

  if (action)
    actionButton = (
      <div
        className={cx(
          'flex gap-2',
          compact ? 'ml-0' : 'self-start md:ml-2 md:self-center'
        )}
      >
        {action}
      </div>
    );

  switch (severity) {
    case 'error':
      icon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="white"
          className="h-6 w-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
          />
        </svg>
      );
      break;
    case 'warning':
      icon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="white"
          className="h-6 w-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
          />
        </svg>
      );
      break;
    case 'info':
      icon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="white"
          className="h-6 w-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
          />
        </svg>
      );
      break;
    case 'success':
      icon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="white"
          className="h-6 w-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      );
      break;
    case 'locked':
      icon = <Lock className="size-4 text-slate-800" />;
      break;
    case 'upgrade':
      icon = (
        <Zap className="size-4 fill-upgrade-default text-upgrade-default" />
      );
      actionButton = (
        <div
          className={cx(
            'flex gap-2',
            compact ? 'ml-0' : 'self-start md:ml-2 md:self-center'
          )}
        >
          <Button upgrade variant="neutral-secondary" onClick={upgradeAction}>
            <FormattedMessage defaultMessage="Upgrade" />
          </Button>
        </div>
      );
      break;
  }

  if (severity !== 'upgrade' && action) {
    actionButton = (
      <div
        className={cx(
          'flex gap-2',
          compact ? 'ml-0' : 'self-start md:ml-2 md:self-center'
        )}
      >
        {action}
      </div>
    );
  }

  if (alternateIcon) {
    icon = alternateIcon;
  }

  if (variant === 'light' && severity !== 'upgrade' && severity !== 'locked') {
    icon = <div />;
  }

  return (
    <div
      className={cx(
        'flex-row items-center rounded-card',
        variantWrapperClasses[variant][severity],
        compact ? 'p-2' : 'px-4 py-3'
      )}
    >
      <div className="flex">
        {icon ? (
          <div className={iconClasses} aria-hidden="true">
            {icon}
          </div>
        ) : null}
        <div
          className={cx(
            'flex flex-1 flex-col flex-wrap items-start justify-between gap-2 md:flex-row md:items-center',
            compact ? 'ml-2' : 'ml-3'
          )}
        >
          <div
            className={cx(
              variantTextClasses[variant][severity],
              'text-sm',
              compact ? 'self-start' : 'md:self-center'
            )}
          >
            {title && (
              <p
                className={cx(
                  variantTitleClasses[variant][severity],
                  'font-semibold text-md'
                )}
              >
                {title}
              </p>
            )}
            {description && (
              <p className={cx(title ? 'mt-2' : 'text-md text-slate-700')}>
                {description}
              </p>
            )}
          </div>
          {actionButton}
        </div>
      </div>
    </div>
  );
};
