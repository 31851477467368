import { configureStore } from '@reduxjs/toolkit';
import { combineReducers, Middleware, UnknownAction } from 'redux';
import { createLogger } from 'redux-logger';
import { isProduction } from '../helpers/firebase/config';
import * as reducers from './modules';

const middleware: Middleware[] = [];
if (!isProduction()) {
  middleware.push(
    createLogger({
      diff: true,
      collapsed: true,
    }) as Middleware<UnknownAction>
  );
}

const newStore = () => {
  return configureStore({
    reducer: combineReducers(reducers),
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(middleware),
  });
};

export let store = newStore();

export const clearStore = () => {
  store = newStore();
};

export type TactiqStore = typeof store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
